/* eslint-disable quotes */
const t = {
  translation: {
    LOGIN_WITH: 'Login with',
    LOGIN_BUTTON_TEXT: 'Login',
    SIGN_UP_LINK_TEXT: 'Sign up',
    NEW_USER_REGISTRATION_TEXT: 'New User Registration',
    FIRSTNAME: 'First name',
    LASTNAME: 'Last name',
    TERMS_ACCEPT: 'I accept the terms and conditions',
    EMAIL: 'Email',
    REPEAT_EMAIL: 'Repeat email',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat password',
    REPEAT_NEW_PASSWORD: 'Repeat new password',
    CHANGE_PASSWORD: 'Change password',
    CREATE_ACCOUNT_BUTTON: 'Create account',
    USER_PROFILE_TITLE: 'User Profile',
    PROFILE_PICTURE_TITLE: 'Profile Picture',
    USER_INTERFACE_PREFERENCES_TITLE: 'User Interface Preferences',
    UI_LANGUAGE_TITLE: 'UI Language',
    SELECT_UI_LANGUAGE: 'Select UI Language',
    UI_LANGUAGES_DISPLAY: 'How languages are displayed',
    SELECT_UI_LANGUAGE_DISPLAY: 'ISO Language name',
    UI_DATES_DISPLAY: 'How dates are displayed',
    SELECT_UI_DATES_DISPLAY: 'YYYY-MM-DD',
    LOGGED_IN_AS: 'Connecté en tant que:',
    SIGN_OUT: 'Sign Out',
    DASHBOARD: 'Dashboard',
    MY_PROJECTS: 'My Projects',
    SHARED_PROJECTS: 'Shared Projects',
    CREATE_NEW: 'Create New',
    NEW_PROJECT: 'New Project',
    CREATE_PROJECT: 'Create Project',
    ACCOUNT_SETTINGS: 'Account Settings',
    NAME: 'Name',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password',
    ACCEPT_INVITE: 'Accept Invite',
    REJECT_INVITE: 'Reject Invite',
    CHANGE_PICTURE: 'Change Picture',
    SAVE_CHANGES: 'Save Changes',
    YOUR_CHANGES_HAVE_BEEN_SAVED: 'Your changes have been saved!',
    SAVED: 'Saved',
    SAVE_CONTINUE: 'Save & Continue',
    FIELD_IS_REQUIRED: 'This field is required.',
    EDIT_PROJECT: 'Edit Project',
    PROJECT_DETAILS: 'Project Details',
    PROJECT_DETAILS_SUBTITLE: 'Basic information for the new project',
    PROJECT_TITLE: 'Project Title',
    PROJECT_ACRONYM: 'Project Acronym',
    PROJECT_DESCRIPTION: 'Project Description',
    INVITE_MEMBERS: 'Invite Members',
    EXISTING_MEMBERS: 'Existing Members',
    INVITE_MEMBERS_TO_PROJECT: 'Invite members to project',
    SEARCH_MEMBERS_BY_NAME: 'Search members to invite by their name',
    SEND_INVITES: 'Send Invites',
    INVITED_TEXT: 'You were invited to join the team',
    BY: 'by',
    REGION_OF_INTEREST: 'Region of Interest',
    PROJECT_DATASETS: 'Project Datasets',
    SELECT_COUNTRY: 'Select Country',
    DEFINE_THE_ROI: 'Define the ROI',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    ACTIVE_PROJECT: 'Active Project',
    COUNTRY_LEVEL: 'Country Level',
    UNCCD_ANNEX: 'UNCCD Annex',
    RISK_PROFILES: 'Risk Profiles',
    LAND_USE_PLANNING: 'Land Use Planning',
    ANTICIPATED_NEW_LAND_DEGRADATION: 'Anticipated new Land Degradation',
    PLAN_FOR_LDN: 'Plan for LDN',
    NEUTRALITY_MATRIX_MAP: 'Neutrality Matrix / Map',
    LAND_USE: 'Land Use',
    LAND_MANAGEMENT: 'Land Management',
    SOIL_ORGANIC_CARBON: 'Soil Organic Carbon',
    OVERVIEW: 'Overview',
    LD_IMPACT_OVERVIEW: 'LD Impact Overview',
    START_YEAR: 'Start Year',
    END_YEAR: 'End Year',
    SCENARIO: 'Scenario',
    NEW_SCENARIO: 'New Scenario',
    DELETE_PROJECT: 'Delete Project',
    LOAD_PROJECT: 'Load Project',
    DELETE_ALL_SCENARIOS: 'Delete All Scenarios',
    YES_DELETE_ALL_SCENARIOS: 'Yes, delete all scenarios',
    ARE_YOU_SURE: 'Are you sure you want to proceed?',
    LOAD_TRENDS_EARTH_DEFAULTS: 'Load Trends.Earth defaults',
    TREE_COVERED: 'Tree-covered',
    GRASSLAND: 'Grassland',
    CROPLAND: 'Cropland',
    WETLAND: 'Wetland',
    ARTIFICIAL_AREA: 'Artificial Area',
    BARE_LAND: 'Bare land',
    WATER_BODY: 'Water body',
    IMPROVEMENT: 'Improvement',
    IMPROVED: 'Improved',
    STABLE: 'Stable',
    DEGRADATION: 'Degradation',
    DEGRADED: 'Degraded',
    LAND_DEGRADATION: 'Land Degradation',
    LAND_DEGRADATION_BALANCE: 'Land Degradation Balance',
    SELECT_STATUS: 'Select Status',
    LU_TRANSITION_IMPACT_MATRIX: 'LU Transition Impact Matrix',
    APPLICABLE_WOCAT_SLM_TECHNOLOGIES: 'Applicable WOCAT SLM Technologies',
    ENTER_KEYWORD: 'Search using keywords',
    SEARCH: 'Search',
    WOCAT_TECHNOLOGIES: 'WOCAT SLM Technologies',
    WOCAT_TECHNOLOGY: 'WOCAT Technology',
    NO_WOCAT_TECHNOLOGIES_FOUND: 'Please search using keywords.',
    NO_WOCAT_TECHNOLOGIES_FOR_THIS_PROJECT:
      'No WOCAT Technologies have been selected for this project.',
    CURRENT_STATE: 'Current State',
    UNCCD_BASELINE: 'UNCCD Baseline',
    LAND_USE_SUITABILITY: 'Land Use Suitability',
    LAND_MANAGEMENT_SUSTAINABILITY: 'Land Management Sustainability',
    USE_DEFAULT_DATA: 'Use default data',
    USE_DEFAULT_SDG_DATA: 'Use default SDG15.3.1 data',
    USE_CUSTOM_DATA: 'Use custom data',
    LU_TYPE_IN_ROI: 'LU Type in ROI',
    SUITABLE: 'Suitable',
    PARTIALLY_SUITABLE: 'Partially Suitable',
    NON_SUITABLE: 'Non Suitable',
    LM_SUSTAINABILITY_IMPACT_INDICATORS: 'LM Sustainability Impact Indicators',
    DEFINE_FOCUS_AREAS_IN_ROI: 'Define Focus Area(s) in ROI',
    ADD_NEW_FOCUS_AREA: 'Add new Focus Area',
    FOCUS_AREA: 'Focus Area',
    FOCUS_AREAS: 'Focus Areas',
    FOCUS_AREA_LM_ASSESMENT: 'Focus Area LM Assessment',
    SAVE_ASSESSMENT: 'Save Assessment',
    SAVE_ASSESSMENT_AND_PROPOSE: 'Save Assessment & Propose',
    NO_FOCUS_AREAS_WARNING: 'You need to define at least one focus area to continue',
    ASSESSMENT_CRITERIA: 'Assessment Criteria',
    SETUP_LU_CLASSES: 'Setup LU Classes',
    CUSTOM_LU_CLASSES: 'Custom LU Classes',
    SETUP_PROJECT: 'Setup Project',
    ADD: 'Add',
    ADD_ENTRY: 'Add Entry',
    NO_PROJECTS_FOUND: 'No projects found',
    NO_ENTRIES: 'No entries have been defined yet.',
    VALUE: 'Value',
    USE_DEFAULT_LU_CLASSIFICATION: 'Use default data',
    USE_CUSTOM_LU_CLASSIFICATION: 'Use custom Land Use map',
    USE_CUSTOM_LAND_DEGRADATION_MAP: 'Use custom Land Degradation map',
    BECOMES: 'BECOMES',
    COLLAPSE: 'Collapse',
    EXPAND: 'Expand',
    ACTIONS: 'Actions',
    UPLOAD_MAP: 'Upload map',
    SELECT_FILE: 'Select file',
    FILE: 'File',
    DOWNLOAD_MAP: 'Download map',
    DOWNLOAD_FILE: 'Download file',
    UPLOAD_LAND_USE_MAP: 'Upload Land Use Map',
    UPLOAD_LAND_SUITABILITY_MAP: 'Upload Land Suitability Map',
    HAS_SUITABILITY_MAP: 'Has Suitability Map',
    UPLOAD_LAND_DEGRADATION_MAP: 'Upload Land Degradation Map',
    CUSTOM_LU_CLASSES_MESSAGE:
      'You should upload a “Land Use Map” and at least one “Suitability Map”',
    UPLOAD_POLYGON: 'Upload polygon',
    PROCESSING_POLYGON: 'Processing polygon',
    CONTINUE_SETUP: 'Continue Setup',
    PREPROCESSING: 'Preprocessing...',
    PROJECT_UNDER_PREPARATION: 'Project is still under preparation.',
    OR_UPLOAD_CUSTOM_POLYGON: 'Or upload custom polygon',
    SCENARIO_LD_IMPACT: 'LD Impact',
    NOT_ENOUGH_SCENARIOS: "You don't have enough scenarios to display the Neutrality Matrix.",
    VIEW_PROJECT_FILES: 'Afficher les fichiers du projet',
    PROJECT_FILES: 'Project files for "{{ project.title }}"',
    COSTS_AND_BENEFITS_OF_SLM_TECHNOLOGIES: 'Costs and benefits',
  },
};

export default t;
